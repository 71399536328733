var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"lineNumbers":true,"search-options":{
            enabled: true,
            placeholder: 'Masukkan NIK atau Nama',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPageDropdownEnabled: true,
            perPageDropdown: [10, 20, 30, 40, 50, 100],
            dropdownAllowAll: false,
            position: 'bottom',
            nextLabel: '',
            prevLabel: '',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All',
          },"selectOptions":{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'text-dark px-1',
            selectionText: 'data dipilih',
            clearSelectionText: '',
          }},on:{"on-selected-rows-change":_vm.handleSelectionChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'status')?_c('span',[_c('div',{staticClass:"switcher"},[_c('input',{attrs:{"type":"checkbox","name":props.row.id_iup,"id":props.row.id_iup},domProps:{"checked":parseInt(props.row.status_aktif)},on:{"click":function($event){return _vm.toggleUser(props.row.id_iup, props.row.status_aktif)}}}),_c('label',{attrs:{"for":props.row.id_iup}})])]):_vm._e(),(props.column.field == 'action')?_c('span',[_c('div',[_c('button',{staticClass:"btn btn-circle btn-icon btn-danger",on:{"click":function($event){return _vm.confirmDelete(props.row.id_saksi)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.tableInfo)+" ")]),_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{staticClass:"btn btn-xs btn-danger m-r-5",on:{"click":_vm.confirmAllDelete}},[_c('i',{staticClass:"fa fa-trash-alt"}),_vm._v(" Hapus Data Terpilih ")])]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-white mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.fetchData()}}},[(_vm.loading)?_c('i',{staticClass:"fa fa-sync fa-spin"}):_c('i',{staticClass:"fa fa-sync"})])])])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("List Saksi")])])
}]

export { render, staticRenderFns }